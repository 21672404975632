<template>
	<div id="app">
		hi
		<a href="ezsub://business.ThemeSquareActivity">a标签跳转</a>

		<div @click="iframe()">iframe</div>

		<div class="auto" @click="appDownload()">auto</div>


		<h2 @click="jumpToClient('ezsub://SubscribedActivity?portal=huodong')">跳转默认链接</h2>
		<input type="text" v-model="jumpurl" >
		<h2 @click="jumpToClient(jumpurl)">跳转配置链接</h2>

		<h2 @click="getUserInfo()">获取用户信息</h2>
		<p>token-->{{ token }}</p>
	</div>
</template>

<style>
div {
	margin-bottom: 30px;
}
</style>

<script>
export default {
	name: 'home',
	data() {
		return {
			token: null,
			appname: 'home',
			jumpurl: '',
		}
	},
	created() {
		this.MobileOperatingSystem = this.$global.getMobileOperatingSystem()
	},
	methods: {
		clientFunc(name, data) {
			/* eslint-disable */
			if (this.MobileOperatingSystem) {
				console.log(`name${name}`, this.MobileOperatingSystem)
				if (name == 'getUserInfo') return JsCallAndroid.getUserInfo()
				if (name == 'jumpNativePage') return JsCallAndroid.jumpNativePage(data)
			}
		},
		jumpToClient(link){
			if (this.MobileOperatingSystem) {
				this.clientFunc('jumpNativePage',link)
			} else {
				console.log('pc');
			}
		},
		getUserInfo() {
			var token = {
				token: '没获取到',
			}

			if (this.MobileOperatingSystem) {
				let clientToken = this.clientFunc('getUserInfo')
				console.log(`token--> ${clientToken}`)
				this.token = JSON.parse(clientToken)
			} else {
				this.token = token
			}
		},
		goto(num) {
			if (num == 1) {
				window.location.href = 'ezsub://business.ThemeSquareActivity?category_one=27253763302493248&category_two=27262009127996480&portal=Harem'
			}
			if (num == 2) {
				window.location.href = 'ezsub://business.TopicHomeActivity?topicId=26763835795875136&portal=KATY'
			}
			if (num == 3) {
				window.location.href = 'ezsub://business.ContentDetailsActivity?resourceId=61936881811740128&portal=xxx'
			}
		},
		appDownload() {
			function failed() {
				window.location.href = 'https://www.baidu.com'
			}

			function transfer(cb) {
				window.location.href = 'ezsub://business.ThemeSquareActivity'
				const initialTime = new Date()
				let counter = 0
				let waitTime = 0
				const checkOpen = setInterval(() => {
					counter++
					waitTime = new Date() - initialTime
					if (waitTime > 3500) {
						clearInterval(checkOpen)
						cb()
					}
					if (counter < 1000) {
						return
					}
				}, 20)

				document.addEventListener('visibilitychange', () => {
					const isHidden = document.hidden
					if (isHidden) {
						clearInterval(checkOpen)
					}
				})
			}
			transfer(failed)
		},
		transfer() {
			window.location.href = 'ezsub://business.ThemeSquareActivity'
			const initialTime = new Date()
			let counter = 0
			let waitTime = 0
			const checkOpen = setInterval(() => {
				counter++
				waitTime = new Date() - initialTime
				if (waitTime > 3500) {
					clearInterval(checkOpen)
				}
				if (counter < 1000) {
					return
				}
			}, 20)

			document.addEventListener('visibilitychange', () => {
				const isHidden = document.hidden
				if (isHidden) {
					clearInterval(checkOpen)
				}
			})
		},

		iframe() {
			var _iframe = document.createElement('iframe')
			_iframe.src = 'ezsub://business.ThemeSquareActivity'
			_iframe.style.display = 'none'
			document.body.appendChild(_iframe)
		},
	},
}
</script>
