import Vue from 'vue'
import App from './App.vue'
import global from '@/utlis/global.js'
import VConsole from 'vconsole'

if (process.env.VUE_APP_MODE == 'test') {
	const vconsole = new VConsole()
}

Vue.prototype.$global = global
Vue.prototype.$api = global.api

window.vm = new Vue({
	render: (h) => h(App),
}).$mount('#app')
